import { API_URL, API_VERSION, URLS } from '@/configs/urls.config';
import { APIResponse, AutoregClickResponse } from '@/core';
import { UserAutoregData } from '@/core/UserAutoregData.interface';
import { isHornyspace } from '@/utils/env-helpers';
import isPreviewMode from '@/utils/is-preview-mode';
import newWindowCanBeOpened from '@/utils/new-window-can-be-opened';
import httpService from './http.service';
import { isPwa } from '@/utils/is-pwa';

export default class OfferService {
  private static refill_form = false;

  private static _redirecting = false;

  private static visit_id: string | null = null;

  private static _is_webview = false;

  private static _is_in_app = false;

  private static _validatedEmailFromVisit?: null | string = null;

  public static setValidatedEmailFromVisit(email?: string | null) {
    this._validatedEmailFromVisit = email;
  }

  public static getValidatedEmailFromVisit() {
    return this._validatedEmailFromVisit;
  }

  public static setVisitId(visit_id: string) {
    this.visit_id = visit_id;
  }

  public static setIsWebview(value: boolean) {
    this._is_webview = value;
  }

  public static setIsInApp(value: boolean) {
    this._is_in_app = value;
  }

  public static async pickOffer(offerType: 'so' | 'bo' | 'wo'): Promise<string | null> {
    if (isPreviewMode()) {
      return null;
    }

    try {
      const { data: body } = await httpService.get<APIResponse>(`${URLS.CLICK}/${offerType}?vid=${this.visit_id || ''}`);
      const { data } = body;

      if (!data.redirect || typeof data.redirect !== 'string') {
        return null;
      }

      return data.redirect;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  private static async handleSecondaryOffer() {
    let offerLink: string | null = await this.pickOffer('so');

    if (!offerLink) {
      offerLink = this.getBackupSOLink();
    }

    window.location.assign(offerLink);
  }

  public static async handleWindowOpener() {
    try {
      const offerLink = await this.pickOffer('wo');
      window.opener.location = offerLink;
    } catch (error) {
      console.log(error);
    }
  }

  public static async redirectToBackButtonOffer() {
    if (isPreviewMode()) {
      // eslint-disable-next-line no-alert
      alert('[Preview Mode] Back button offer redirect triggered');
      return;
    }

    let offerLink: string | null = await this.pickOffer('bo');

    if (!offerLink) {
      offerLink = this.getBackupBBLink();
    }

    window.location.assign(offerLink);
  }

  public static handleOfferRedirect(offerLink: string) {
    if (isPreviewMode()) {
      // eslint-disable-next-line no-alert
      alert('[Preview Mode] This is the end of this landing');
      return;
    }

    if (this._redirecting) {
      return;
    }

    this._redirecting = true;

    if (this._is_in_app || this._is_webview || !newWindowCanBeOpened()) {
      window.location.assign(offerLink);
      return;
    }

    let mainOfferWindow: Window | null;

    try {
      mainOfferWindow = window.open('about:blank', '_blank');
    } catch (error) {
      console.error(error);
      mainOfferWindow = null;
    }

    setTimeout(() => {
      if (!mainOfferWindow || mainOfferWindow.closed) {
        mainOfferWindow = window;
      }

      mainOfferWindow.location.assign(offerLink);

      try {
        if (mainOfferWindow !== window) {
          this.handleSecondaryOffer();
        }
      } catch (error) {
        console.error(error);
      }
    });
  }

  public static async handleDirectClick(): Promise<void> {
    if (isPreviewMode()) {
      // eslint-disable-next-line no-alert
      alert('[Preview Mode] This is the end of this landing');
      return;
    }

    // if (isPwa() || this._is_in_app || this._is_webview) {
    if (this._is_in_app || this._is_webview) {
      window.location.assign(this.composeInAppClickUrl());
      return;
    }

    let mainOfferWindow: Window | null = null;

    try {
      if (newWindowCanBeOpened()) {
        mainOfferWindow = window.open('about:blank', '_blank');
      }
    } catch (error) {
      console.error(error);
      mainOfferWindow = null;
    }

    let redirectLink: string | null = null;

    try {
      const { data: body } = await httpService.post<APIResponse>(`${URLS.CLICK_PRELAND}?vid=${this.visit_id || ''}`);
      const { data } = body;

      if (data.redirect && typeof data.redirect === 'string') {
        redirectLink = data.redirect;
      } else {
        redirectLink = this.getBackupDirectLink();
      }
    } catch (error) {
      console.error(error);
      redirectLink = this.getBackupDirectLink();
    }

    if (!mainOfferWindow || mainOfferWindow.closed) {
      mainOfferWindow = window;
    }

    mainOfferWindow.location.assign(redirectLink);

    try {
      if (mainOfferWindow !== window) {
        this.handleSecondaryOffer();
      }
    } catch (error) {
      console.log(error);
    }
  }

  private static composeInAppClickUrl(userData?: UserAutoregData): string {
    const queryParams = new URLSearchParams();

    try {
      if (userData) {
        const dataEncoded = encodeURIComponent(btoa(JSON.stringify(userData)));
        queryParams.append('d', dataEncoded);
      }

      if (this.visit_id) {
        queryParams.append('vid', this.visit_id);
      }
    } catch (error) {
      console.error(error, (error as any)?.stack);
    }

    return `${API_URL}/${API_VERSION}${URLS.CLICK_PRELAND}?${queryParams.toString()}`;
  }

  public static async handleDirectClickWithoutSecondaryOffer() {
    // if (isPwa() || this._is_in_app || this._is_webview) {
    if (this._is_in_app || this._is_webview) {
      window.location.assign(this.composeInAppClickUrl());
      return;
    }
    
    let redirectLink: string;

    try {
      const { data: body } = await httpService.post<AutoregClickResponse>(URLS.CLICK_PRELAND);
      const { success, data } = body;

      if (!success || !data.redirect) {
        redirectLink = OfferService.getBackupDirectLink();
      } else {
        redirectLink = data.redirect;
      }

    } catch (error) {
      console.error(error);
      redirectLink = OfferService.getBackupDirectLink();
    }

    window.location.assign(redirectLink);
  }

  public static async submitAutoregClick(userData: UserAutoregData): Promise<AutoregClickResponse> {
    if (isPreviewMode()) {
      return { success: false, data: {} };
    }

    // if (isPwa() || this._is_webview || this._is_in_app) {
    if (this._is_webview || this._is_in_app) {
      return { success: true, data: { redirect: this.composeInAppClickUrl(userData) } };
    }

    try {
      const { status, data: body } = await httpService.post<APIResponse>(
        URLS.CLICK_PRELAND,
        { ...userData, refill_form: this.refill_form },
      );
      const { success, data } = body;

      if (data?.refill_form === true) {
        this.refill_form = true;
      }

      return { status, success, data };
    } catch (error) {
      console.error(error);
      return { success: false, data: {} };
    }
  }

  public static backupDirectRedirect() {
    if (isPreviewMode()) {
      // eslint-disable-next-line no-alert
      alert('[Preview Mode] This is the end of this landing');
      return;
    }

    window.location.assign(this.getBackupDirectLink());
  }

  public static getBackupDirectLink() {
    if (isHornyspace()) {
      return 'https://r.flirtooy.me/c/60f7dd13-3122-429b-8b9b-6f6ad2933671?p6=direct&p7=hornyspace&p8=frontend';
    }

    return 'https://r.flirtooy.me/c/60f7dd13-3122-429b-8b9b-6f6ad2933671?p6=direct&p7=flirtooy&p8=frontend';
  }

  public static getBackupSOLink() {
    if (isHornyspace()) {
      return 'https://r.flirtooy.me/c/60f7dd13-3122-429b-8b9b-6f6ad2933671?p6=so&p7=hornyspace&p8=frontend';
    }

    return 'https://r.flirtooy.me/c/60f7dd13-3122-429b-8b9b-6f6ad2933671?p6=so&p7=flirtooy&p8=frontend';
  }

  public static getBackupBBLink() {
    if (isHornyspace()) {
      return 'https://r.flirtooy.me/c/60f7dd13-3122-429b-8b9b-6f6ad2933671?p6=bo&p7=hornyspace&p8=frontend';
    }

    return 'https://r.flirtooy.me/c/60f7dd13-3122-429b-8b9b-6f6ad2933671?p6=bo&p7=flirtooy&p8=frontend';
  }
}
